import PropTypes from 'prop-types';
import React from 'react';
import { useRouter } from 'next/router';

// ---------------------------------------------------------

import RealtorCard from '@components/realtor-card';

// ---------------------------------------------------------

import { two_agents, realtor_card } from './styles.module.scss';

// ---------------------------------------------------------

const FormRealtorCards = ({
  primaryContact,
  secondaryContact,
  hasTour,
  hasSecondaryAgent,
  setModalIsOpen
}) => {
  const router = useRouter();
  const onListingPage = router.pathname.startsWith('/listing/');
  // eslint-disable-next-line no-unused-vars

  // ---------------------------------------------------------

  return hasSecondaryAgent ? (
    <div className={two_agents}>
      <RealtorCard
        contact={primaryContact}
        theme="horizontalSmall"
        onListingPage={onListingPage}
        hasTour={hasTour}
        setModalIsOpen={setModalIsOpen}
        imageDimensions={{ height: 119, width: 85 }}
      />
      <RealtorCard
        theme="horizontalSmall"
        onListingPage={onListingPage}
        contact={secondaryContact}
        hasTour={hasTour}
        setModalIsOpen={setModalIsOpen}
        imageDimensions={{ height: 119, width: 85 }}
      />
    </div>
  ) : (
    <div className={realtor_card}>
      <RealtorCard
        theme="primary"
        onListingPage={onListingPage}
        contact={primaryContact}
        hasTour={hasTour}
        setModalIsOpen={setModalIsOpen}
        imageDimensions={{ height: 152, width: 110 }}
      />
    </div>
  );
};

FormRealtorCards.defaultProps = {
  setModalIsOpen: () => {}
};

FormRealtorCards.propTypes = {
  primaryContact: PropTypes.object.isRequired,
  secondaryContact: PropTypes.object,
  hasTour: PropTypes.bool,
  hasSecondaryAgent: PropTypes.bool,
  setModalIsOpen: PropTypes.func
};

export default FormRealtorCards;
