import { useState, useEffect, useMemo } from 'react';
import { useRouter } from 'next/router';
import { useAgentData } from '@lib/hooks';

// ---------------------------------------------------------

import PropertiesTab from './properties-tab';
import Content from '@components/content';
import Container from '@layout/container';
import Jumbotron from '@components/jumbotron';
import Layout from '@layout/index';
import TabSlider from '@components/tab-slider/component';
import Form from '@components/forms';
import TopicCarousel from '@components/topic-carousel';
import PhotoGallery from '@components/photo-gallery';
import EnhancedContactForm from '@components/forms/enhanced';
import FormRealtorCards from '@components/forms/enhanced/realtor-cards';
import Accordion from '@components/accordion';

// ---------------------------------------------------------

import {
  container,
  side_bar,
  content_container,
  tabs,
  photos_container,
  accordion_container,
  red_caret_theme_override
} from './styles.module.scss';

// ---------------------------------------------------------

const BuilderDevelopmentTemplate = (props) => {
  const { data, preview, associatedAgent: associatedAgentProp } = props;
  const router = useRouter();

  const {
    associatedAgent,
    setAssociatedAgent,
    setAssociatedAgentToSessionStorage,
    getAssociatedAgentFromSessionStorage
  } = useAgentData(associatedAgentProp);

  const [selectedTab, setSelectedTab] = useState('about');

  const tabItems = [
    { label: 'About', value: 'about' },
    { label: 'Properties', value: 'properties' },
    data.photos?.items?.length > 0 || data.photoRichText
      ? { label: 'Photos', value: 'photos' }
      : null
  ];

  useEffect(() => {
    if (!router.isReady) return;

    if (router.query.tab) {
      if (tabItems.findIndex((item) => item.value === router.query.tab.toLowerCase()) !== -1) {
        setSelectedTab(router.query.tab.toLowerCase());
      }
    }

    // set agent data to session storage if it was provided by the server
    if (associatedAgentProp) {
      setAssociatedAgentToSessionStorage(associatedAgentProp);
    }
  }, [associatedAgentProp]);

  useEffect(() => {
    // set associated agent data if it exists in session storage
    // for a case where the user comes from a page with an agentid in the query
    if (data?.allAgentPermission && !associatedAgentProp) {
      const agentData = getAssociatedAgentFromSessionStorage();
      if (agentData) {
        setAssociatedAgent(agentData);
      }
    }
  }, [router, associatedAgentProp]);

  const handleSwitchTabs = (newTab) => {
    const newQuery = {
      ...router.query,
      tab: newTab
    };
    if ('properties' in newQuery) {
      delete newQuery.properties;
    }
    router.replace({ query: newQuery }, undefined, {
      shallow: true
    });
    setSelectedTab(newTab);
  };

  const accordionItems = [
    'featuresText',
    'developmentPlat',
    'floorplans',
    'elevations',
    'schoolInformation'
  ];
  const accordionItemsTitleMap = {
    developmentPlat: 'Development Plat',
    elevations: 'Elevations',
    featuresText: 'Features & Amenities',
    floorplans: 'Floorplans',
    schoolInformation: 'School Information'
  };

  const getAccordionItems = () => {
    let arr = [];

    accordionItems.map((section) => {
      const sectionContent = data[section];

      if (sectionContent) {
        arr.push({
          body: (
            <div>
              <Content content={sectionContent} />
            </div>
          ),
          id: section,
          label: accordionItemsTitleMap[section]
        });
      }
    });

    return arr;
  };

  const getCarouselUrl = (item) => {
    if (item.__typename === 'Community') {
      return `/community/${item?.slug}`;
    } else if (item.category === 'Builder') {
      return `/builder/${item?.slug}`;
    } else if (item.category === 'Development') {
      return `/development/${item?.slug}`;
    } else {
      return `/${item?.slug}`;
    }
  };

  const getDefaultLinkLabel = (item) => {
    if (item.category === 'Builder') {
      return 'View Builder';
    } else if (item.category === 'Development') {
      return 'View Development';
    } else {
      return 'View Community';
    }
  };

  useEffect(() => {
    // set agent data to session storage if it was provided by the server
    if (associatedAgentProp) {
      setAssociatedAgentToSessionStorage(associatedAgentProp);
    }
  }, [associatedAgentProp]);

  useEffect(() => {
    // set associated agent data if it exists in session storage
    // for a case where the user comes from a page with an agentid in the query
    if (data?.allAgentPermission && !associatedAgentProp) {
      const agentData = getAssociatedAgentFromSessionStorage();
      if (agentData) {
        setAssociatedAgent(agentData);
      }
    }
  }, [router, associatedAgentProp]);

  const carouselSlides =
    data.nearbyCommunities?.items?.map((item) => ({
      description: item?.excerpt || item?.summaryText || item?.introductionText,
      featuredImage: item?.featuredImage || {
        height: 960,
        url: 'https://images.ctfassets.net/wzzep3ntdgx4/4V3cWpoV2LctBsJYc6SnSf/4ef45ecd14a78222b508078d9fe9d345/AdobeStock_376819599.jpeg',
        width: 1440
      },
      linkLabel: item?.linkLabel || getDefaultLinkLabel(item),
      linkUrl: getCarouselUrl(item),
      title: item?.title || item?.builderDevelopmentName
    })) || [];

  const routerDefaultTab = useMemo(() => {
    if (router.query.tab) {
      if (tabItems.findIndex((item) => item.value === router.query.tab.toLowerCase()) !== -1) {
        return router.query.tab;
      }
    }
    return null;
  }, [router]);

  return (
    <Layout
      preview={preview}
      meta={{
        description: data?.metaDescription || data?.excerpt,
        image: data?.metaImage || data?.featuredImage,
        title: data?.metaTitle || data?.builderDevelopmentName
      }}
    >
      <Jumbotron
        ctas={[
          {
            className: red_caret_theme_override,
            label: data.category === 'Builder' ? 'Premier Builders' : 'All Developments',
            theme: 'red-text-only-with-caret',
            url: data.category === 'Builder' ? '/builder' : '/development'
          },

          ...(data.primaryLink
            ? [
                {
                  label: data.linkLabel ? data.linkLabel : 'Learn more',
                  theme: 'red',
                  url: data.primaryLink
                }
              ]
            : [])
        ]}
        theme="left-centered"
        image={{
          height: '500px',
          url:
            data.headerImage?.url ||
            'https://images.ctfassets.net/wzzep3ntdgx4/B2zZsgoajAu2j4IvSpp9h/1baff99d5c5134b3fe8e014a747f2075/IMG_8039.jpg',
          width: '1440px'
        }}
        title={data.builderDevelopmentName}
      />
      <Container removeBottomSpacing spacing="medium" theme="background-is-white">
        <div className={container}>
          <div className={content_container}>
            <div className={tabs}>
              <TabSlider
                items={tabItems}
                theme="default"
                defaultSelection={(routerDefaultTab ?? 'about').toLowerCase()}
                setSelectedTabCallback={handleSwitchTabs}
              ></TabSlider>
            </div>
            {selectedTab === 'about' && (
              <>
                <Content content={data.body} />
                <div className={data.body && accordion_container}>
                  <Accordion listItems={{ items: getAccordionItems() }}></Accordion>
                </div>
              </>
            )}
            {selectedTab === 'properties' && (
              <PropertiesTab
                builderSlug={data.category === 'Builder' ? data.slug : ''}
                developmentSlug={data.category === 'Development' ? data.slug : ''}
              />
            )}
            {selectedTab === 'photos' && (data.photos?.items?.length > 0 || data.photoRichText) && (
              <div className={photos_container}>
                {data.photoRichText && <Content content={data.photoRichText} />}
                {data.photos?.items?.length > 0 && (
                  <PhotoGallery
                    triggerFirstSort={selectedTab === 'photos'}
                    images={data.photos?.items}
                    fullWidthGrid={false}
                  />
                )}
              </div>
            )}
          </div>

          {associatedAgent && (
            <div className={side_bar}>
              <EnhancedContactForm
                primaryAgent={associatedAgent}
                sticky={true}
                endpoint={'builder/development information request'}
                agentId={associatedAgent.agentId || ''}
                title={data.builderDevelopmentName}
                category={data.category.toLowerCase()}
                endpointId={data.slug}
              >
                <FormRealtorCards primaryContact={associatedAgent} />
              </EnhancedContactForm>
            </div>
          )}
        </div>
      </Container>

      {/* NEARBY COMMUNITIES SECTION */}
      {data.nearbyCommunities?.items?.length > 0 && (
        <Container layout="single" theme="background-is-gray">
          <TopicCarousel
            carouselHeader={
              data.category === 'Builder'
                ? `Build with ${data.builderDevelopmentName}`
                : 'Explore Local Lifestyle'
            }
            slidesCollection={{
              cards: carouselSlides
            }}
            theme="full-width"
          />
        </Container>
      )}

      {/* METRO ACCORDION SECTION */}
      {data.metro?.accordion?.listItems?.items?.length > 0 && (
        <Container
          header={data.metro?.name}
          padding="large"
          width="medium"
          textAlignment="center"
          theme="background-is-white"
        >
          <Accordion theme="red" listItems={data.metro?.accordion?.listItems} />
        </Container>
      )}

      {/* CONTACT FORM SECTION */}
      {data?.contactForm && (
        <Form
          {...data?.contactForm}
          agentId={(data?.agentid?.length > 0 && data?.agentid[0]) || ''}
          title={data.builderDevelopmentName}
          category={data.category.toLowerCase()}
          endpointId={data.slug}
          endpoint={'builder/development information request'}
        />
      )}
    </Layout>
  );
};

export default BuilderDevelopmentTemplate;
