export default {
  props: {
    hasSecondaryAgent: false,
    primaryContact: {
      email: 'hello@example.com',
      photoUrl: 'https://i.stack.imgur.com/HQwHI.jpg',
      languages: 'Spanish',
      name: 'Jason Kenson',
      phone: '555-555-5555',
      professionalTitle: 'Realtor',
      websiteUrl: 'https://www.sibcycline.com',
      teamName: 'Team Kenson'
    },
    secondaryContact: {
      email: '',
      photoUrl: '',
      languages: '',
      name: '',
      phone: '',
      professionalTitle: '',
      websiteUrl: ''
    }
  }
};
