import PropTypes from 'prop-types';
import React, { useEffect, useRef, useState } from 'react';
import { useRouter } from 'next/router';

// ---------------------------------------------------------

import ContactForm from '@components/forms';
import useWindowSize from '@lib/window-size';

// ---------------------------------------------------------

import { container, subheader } from './styles.module.scss';

// ---------------------------------------------------------

const EnhancedContactForm = (props) => {
  const {
    children,
    defaultFormValue,
    endpoint,
    contactText,
    hasTour,
    hasSecondaryAgent,
    primaryAgent,
    listingId,
    userInfo,
    sticky,
    communityName,
    communityState,
    blogPath,
    blogTitle,
    agentId,
    setModalIsOpen,
    title,
    category
  } = props;
  const ref = useRef();
  const [referenceHeight, setReferenceHeight] = useState(0);
  const router = useRouter();
  const onListingPage = router.pathname.startsWith('/listing/');

  useEffect(() => {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({ event: 'chat-visible' });

    setReferenceHeight(ref.current?.clientHeight || 0);
  }, []);

  // ---------------------------------------------------------

  return (
    <div
      className={container}
      ref={ref}
      id="isSibcyClineListing"
      style={{
        position: referenceHeight + 50 < useWindowSize().height && sticky ? 'sticky' : 'inherit',
        top: referenceHeight + 50 < useWindowSize().height && sticky ? '20px' : '0px'
      }}
    >
      {children}
      <span className={subheader}>
        {contactText ? (
          <>{contactText}</>
        ) : (
          <>
            Contact{' '}
            {hasSecondaryAgent ? `us` : primaryAgent?.nickname || primaryAgent?.displayPrimary} to
            learn&nbsp;more
          </>
        )}
        {hasTour && onListingPage ? (
          <>
            &nbsp;or&nbsp;
            <button onClick={() => setModalIsOpen(true)}> request a tour!</button>
          </>
        ) : (
          '!'
        )}
      </span>
      <ContactForm
        category={category}
        title={title}
        theme="no-background"
        linkLabel="Send Message"
        endpoint={endpoint}
        endpointId={listingId}
        defaultFormValue={defaultFormValue}
        userInfo={userInfo}
        communityName={communityName}
        communityState={communityState}
        agentId={agentId}
        blogPath={blogPath}
        blogTitle={blogTitle}
      ></ContactForm>
    </div>
  );
};

EnhancedContactForm.propTypes = {
  children: PropTypes.node,
  agentId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  blogPath: PropTypes.string,
  blogTitle: PropTypes.string,
  category: PropTypes.string,
  communityName: PropTypes.string,
  communityState: PropTypes.string,
  contactText: PropTypes.string,
  defaultFormValue: PropTypes.string,
  endpoint: PropTypes.string,
  hasSecondaryAgent: PropTypes.bool,
  hasTour: PropTypes.bool,
  listingId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  contactName: PropTypes.string,
  setModalIsOpen: PropTypes.func,
  sticky: PropTypes.bool,
  title: PropTypes.string,
  userInfo: PropTypes.object,
  primaryAgent: PropTypes.object,
  secondaryAgent: PropTypes.object
};

EnhancedContactForm.defaultProps = {
  endpoint: 'property information request'
};

export default EnhancedContactForm;
