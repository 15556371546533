const sharedProps = {
  contact: {
    email: 'hello@example.com',
    photoUrl: 'https://i.stack.imgur.com/HQwHI.jpg',
    languages: 'Spanish',
    name: 'Jason Kenson',
    phone: '555-555-5555',
    professionalTitle: 'Realtor',
    websiteUrl: 'https://www.sibcycline.com'
  }
};

export default {
  horizontalLarge: {
    ...sharedProps,
    theme: 'horizontalLarge'
  },
  horizontalSmall: {
    ...sharedProps,
    theme: 'horizontalSmall'
  },
  officeDetail: {
    ...sharedProps,
    theme: 'officeDetail'
  },
  primary: {
    ...sharedProps,
    theme: 'primary'
  },
  props: {
    ...sharedProps
  }
};
