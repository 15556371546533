import FormRealtorCards from '@components/forms/enhanced/realtor-cards';

export default {
  props: {
    hasSecondaryAgent: false,
    contactName: 'Jason Kenson',
    tourUrl: 'https://www.sibcycline.com',
    children: (
      <FormRealtorCards
        hasSecondaryAgent={false}
        primaryContact={{
          email: 'hello@example.com',
          languages: 'Spanish',
          name: 'Jason Kenson',
          photoUrl: 'https://i.stack.imgur.com/HQwHI.jpg',
          phone: '555-555-5555',
          professionalTitle: 'Realtor',
          websiteUrl: 'https://www.sibcycline.com'
        }}
      />
    )
  }
};
