import React from 'react';

import Component from './component';
import fixtures from './fixtures';
import transform from './transformer';

const EnhancedContactForm = (props) => <Component {...transform(props)} />;

export default EnhancedContactForm;

export { Component as component, fixtures, transform as transformer };
